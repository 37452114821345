import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faFile, faFileInvoiceDollar, faPrint, faShoppingCart, faUndoAlt, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Reimpressao from "./Reimpressao";
import { Button, Modal } from "antd";
import Cancelamento from "./Cancelamento";
import TelaVendas from "./TelaVendas";

// Componentes que serão exibidos no modal
const Orcamentos = () => <h1>Orçamentos</h1>;
const Devolucao = () => <h1>Devolução</h1>;
const Log = () => <h1>Log</h1>;

const Vendas = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [pagamentoConcluido, setPagamentoConcluido] = useState(false);
  const [pagamentos, setPagamentos] = useState([]);
  const [produtos, setProdutos] = useState([]);

  const handleOpenModal = (component) => {
    setModalContent(component);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setModalContent(null);
  };

  
  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const trataProdutos = produto => {
    setProdutos([...produtos, produto]);
  };

  return (
    <Container>
      
      <Button 
      style={{ ...styles.buttonStyled, backgroundColor: '#841584' }}
      onClick={() => handleOpenModal(<TelaVendas/>)}
      >
        <FontAwesomeIcon icon={faShoppingCart} size="2x" />
        <ButtonText>Vendas</ButtonText>
      </Button>
      <Button  onClick={() => handleOpenModal(<Orcamentos />)} style={{ ...styles.buttonStyled, backgroundColor: '#FFA500' , opacity: '0.5'}} disabled={true}>
        <FontAwesomeIcon icon={faFileInvoiceDollar} size="2x" />
        <ButtonText>Orçamentos</ButtonText>
      </Button>
      <Button  onClick={() => handleOpenModal(<Cancelamento />)} style={{ ...styles.buttonStyled, backgroundColor: '#FF6347' }} > 
        <FontAwesomeIcon icon={faBan} size="2x" />
        <ButtonText>Cancelamento</ButtonText>
      </Button>
      <Button  onClick={() => handleOpenModal(<Devolucao />)}  style={{ ...styles.buttonStyled, backgroundColor: '#4682B4' , opacity: '0.5' }} disabled={true}>
        <FontAwesomeIcon icon={faUndoAlt} size="2x" />
        <ButtonText>Devolução</ButtonText>
      </Button>
      <Button onClick={() => handleOpenModal(<Reimpressao />)}  style={{ ...styles.buttonStyled, backgroundColor: '#32CD32' }}>
        <FontAwesomeIcon icon={faPrint} size="2x" />
        <ButtonText>Reimpressão</ButtonText>
      </Button>
      <Button onClick={() => handleOpenModal(<Log />)}  style={{ ...styles.buttonStyled, backgroundColor: '#000' , opacity: '0.5' }} disabled={true}>
        <FontAwesomeIcon icon={faFile} size="2x" />
        <ButtonText>Log</ButtonText>
      </Button>

      <Modal
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width="100vw"
        bodyStyle={{ padding: 0 }}
        centered
        closeIcon={null} // Remove o ícone de fechamento padrão
      >
        <ModalHeader>
          <Button onClick={handleCloseModal} type="link" icon={<FontAwesomeIcon icon={faArrowLeft} />} />
        </ModalHeader>
        <ModalBody>{modalContent}</ModalBody>
      </Modal>
    </Container>
  );
};



const styles = {
  buttonStyled: {
    width: '150px',
    height: '150px',
    border: 'none',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontSize: '16px',
    margin: '10px',
  },
};


const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  position: relative;
`;


const ButtonText = styled.span`
  margin-top: 10px;
  font-weight: bold;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 0;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 16px;
  background: #fff;
`;

const ModalBody = styled.div`
  padding: 0;
  margin:0;
  height: calc(100vh - 19vh); // Ajusta a altura do corpo do modal
`;

export default Vendas;
