import React, { useEffect, useState } from 'react';
import Pesquisar from './Pesquisar';
import { Card, Spin } from 'antd';
import './TelaVendas.css'; // Assumindo que os estilos foram movidos para um arquivo CSS
import ModalItemSelecionado from './ModalItemSelecionado';
import FinalizarVenda from './FinalizarVenda';

const TelaVendas = () => {
  const [isPesquisar, setIsPesquisar] = useState(false); // Estado para controlar a exibição do conteúdo de pesquisa
  const [isFinalizarVenda, setIsFinalizarVenda] = useState(false); // Estado para controlar a exibição do conteúdo de finalizar venda

  const [acresDescFreteConcluido, setAcresDescFreteConcluido] = useState(false);
  const [pagamentoConcluido, setPagamentoConcluido] = useState(false);
  const [pagamentos, setPagamentos] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [quantidadeDeItens, setQuantidadeDeItens] = useState(0);
  const [precoTotal, setPrecoTotal] = useState(0);
  const [totalSemFrete, setTotalSemFrete] = useState(0);
  const [totalDescontos, setTotalDescontos] = useState(0);
  const [totalAcrescimos, setTotalAcrescimos] = useState(0);
  const [subTotal, setSubtotal] = useState(0);
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const operador = window.localStorage.getItem("operador");
  const [isLoading, setIsLoading] = useState(false);
  const [nomeCupom, setNomeCupom] = useState('');
  const [documentoCupom, setDocumentoCupom] = useState('');
  const [numeroEvento, setnumeroEvento] = useState('');
  const [isModalVisibleBuscarOrcamento, setIsModalVisibleBuscarOrcamento] =
    useState(false);
  const [selectedProduto, setSelectedProduto] = useState(null);
  const [isModalVisibleItem, setModalVisibleItem] = useState(false);


  const [cardAberto, setCardAberto] = useState('');
  const [consumidorIdentificado, setConsumidorIdentificado] = useState(false);
  const [consumidor, setConsumidor] = useState(null);
  const [acresDescFrete, setAcresDescFrete] = useState({
    acresTotal: '0',
    descTotal: '0',
    frete: '0',
  });
  const [pagItem, setPagItem] = useState([]);






  const [recalcular, setRecalcular] = useState(false);


  const handlePesquisarClick = () => {
    setIsPesquisar(true); // Mostrar a tela de pesquisa
  };

  const handleVoltarClick = () => {
    setIsPesquisar(false); // Voltar para a tela de vendas
  };

  const trataProdutos = produto => {
    setProdutos([...produtos, produto]);
    
  };


  const getDataAtual = () => {
    const dataAtual = new Date();
    const dia = String(dataAtual.getDate()).padStart(2, '0');
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    const ano = dataAtual.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  const CalcularTotais = () => {
    var quantidadeDeItensAux = 0;
    var precoTotalAux = 0;
    var totalSemFreteAux = 0;
    var subTotalAux = 0;
    var totalDescontosAux = 0;
    var totalAcrescimosAux = 0;

    produtos.map(produto => {
      quantidadeDeItensAux = quantidadeDeItensAux + produto.quantidade;

      subTotalAux = subTotalAux + produto.preco * produto.quantidade;
      totalSemFreteAux =
        totalSemFreteAux +
        produto.preco * produto.quantidade -
        parseFloat(produto.valorDesconto) +
        parseFloat(produto.valorAcrescimo);

      precoTotalAux =
        precoTotalAux +
        produto.preco * produto.quantidade -
        parseFloat(produto.valorDesconto) +
        parseFloat(produto.valorAcrescimo) +
        parseFloat(produto.frete ? produto.frete : 0);

      console.log('precoTotalAux');
      console.log('precoTotalAux');
      console.log('precoTotalAux');
      console.log(precoTotalAux);

      totalDescontosAux += parseFloat(produto.valorDesconto);
      totalAcrescimosAux += parseFloat(produto.valorAcrescimo);
    });
    setQuantidadeDeItens(quantidadeDeItensAux);
    setPrecoTotal(precoTotalAux);
    setTotalSemFrete(totalSemFreteAux);
    setSubtotal(subTotalAux);
    setTotalDescontos(totalDescontosAux);
    setTotalAcrescimos(totalAcrescimosAux);
  };

  const openModalItem = produto => {
    setSelectedProduto(produto);
    setModalVisibleItem(!isModalVisibleItem);
  };
  useEffect(() => {
    CalcularTotais();
  }, [produtos, recalcular]);

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', backgroundColor: '#f9f9f9' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Vendas</h1>

      {/* Se isPesquisar for verdadeiro, exibe o conteúdo de pesquisa */}
      {isPesquisar ? (
          <Pesquisar  
          onItemPress={trataProdutos}
          setPagamentoConcluido={setPagamentoConcluido}
          setPagamentos={setPagamentos} 
          closeModal={handleVoltarClick}
          ></Pesquisar>
      ) : 
        isFinalizarVenda? (<FinalizarVenda
          closeModal={()=>{setIsFinalizarVenda(false)}}
          onItemPress={trataProdutos}
          setConsumidorIdentificado={setConsumidorIdentificado}
          consumidorIdentificado={consumidorIdentificado}
          nomeCupom={nomeCupom}
          documentoCupom={documentoCupom}
          setNomeCupom={setNomeCupom}
          setDocumentoCupom={setDocumentoCupom}
          consumidor={consumidor}
          setConsumidor={setConsumidor}
          precoTotal={precoTotal}
          pagamentoConcluido={pagamentoConcluido}
          setPagamentoConcluido={setPagamentoConcluido}
          pagamentos={pagamentos}
          setPagamentos={setPagamentos}
          produtos={produtos}
          setProdutos={setProdutos}
          subTotal={subTotal}
          CalcularTotais={CalcularTotais}
          acresDescFrete={acresDescFrete}
          setAcresDescFrete={setAcresDescFrete}
          totalDescontos={totalDescontos}
          totalAcrescimos={totalAcrescimos}
          acresDescFreteConcluido={acresDescFreteConcluido}
          setAcresDescFreteConcluido={setAcresDescFreteConcluido}
          setPagItem={setPagItem}
          pagItem={pagItem}
        
        ></FinalizarVenda>) :  (
        isModalVisibleItem ? (
          <ModalItemSelecionado
          isVisible={isModalVisibleItem}
          onClose={() => setModalVisibleItem(false)}
          onSave={updatedProduto => {
            console.log(updatedProduto);
            produtos[updatedProduto.index] = updatedProduto;
            //produto = updatedProduto;
            setRecalcular(!recalcular);
            setPagamentoConcluido(false);
            setPagamentos([]);
          }}
          onDelete={item => {
            produtos.splice(item.index, 1);
            setRecalcular(!recalcular);
            setPagamentoConcluido(false);
            setPagamentos([]);
          }}
          produto={selectedProduto}
        />
      ) : (
        // Caso contrário, exibe a tela de vendas
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <button
              style={{
                flex: 1,
                marginRight: '10px',
                backgroundColor: '#800080',
                color: '#fff',
                padding: '15px',
                borderRadius: '5px',
                border: 'none',
                fontSize: '16px',
              }}
            >
              <span style={{ display: 'block', fontSize: '24px' }}>📷</span>
              Ler Código
            </button>
            <button
              onClick={handlePesquisarClick} // Exibe a tela de pesquisa
              style={{
                flex: 1,
                marginRight: '10px',
                backgroundColor: '#000',
                color: '#fff',
                padding: '15px',
                borderRadius: '5px',
                border: 'none',
                fontSize: '16px',
              }}
            >
              <span style={{ display: 'block', fontSize: '24px' }}>🔍</span>
              Pesquisar
            </button>
            <button
              style={{
                flex: 1,
                backgroundColor: '#90ee90',
                color: '#000',
                padding: '15px',
                borderRadius: '5px',
                border: 'none',
                fontSize: '16px',
              }}
              onClick={()=>{setIsFinalizarVenda(true)}} // Exibe a tela de pesquisa
            >
              <span style={{ display: 'block', fontSize: '24px' }}>✔️</span>
              Finalizar Venda
            </button>
          </div>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <button
              style={{
                backgroundColor: '#000',
                color: '#fff',
                padding: '15px',
                borderRadius: '5px',
                border: 'none',
                fontSize: '16px',
                width: '100%',
              }}
            >
              <span style={{ display: 'block', fontSize: '24px' }}>📄</span>
              Buscar Orçamento
            </button>
          </div>
          <div style={{ backgroundColor: '#d3d3d3', padding: '15px', borderRadius: '5px' }}>
            <p style={{ margin: '5px 0' }}>{getDataAtual()}</p>
            <p style={{ margin: '5px 0' }}>{`Operador: ${operador}`}</p>
            <p style={{ margin: '5px 0' }}>Total da Venda: {totalSemFrete.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}</p>
            <p style={{ margin: '5px 0' }}> {`Total de Itens na Venda: ${quantidadeDeItens}`}</p>
          </div>


          {produtos.map((produto, index) => {
          produto.index = index;
          console.log(produto);

          return (
            <>
              {isLoading ? (
                <Spin />
              ) : (



                <div className='cardT' onClick={()=>{openModalItem(produto)}}>
                  <span className="cardTextNome">{produto.nome}</span>
                  <span className="cardText">{`${produto.ean}`}</span>
                  <span className="cardText">
                    {`${produto.quantidade} X ${produto.preco.toLocaleString(
                      'pt-BR',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )} = ${(produto.quantidade * produto.preco).toLocaleString(
                      'pt-BR',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )} - ${parseFloat(produto.valorDesconto).toLocaleString(
                      'pt-BR',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )} + ${parseFloat(produto.valorAcrescimo).toLocaleString(
                      'pt-BR',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )} = ${(
                      parseFloat(produto.quantidade) *
                        parseFloat(produto.preco) -
                      parseFloat(produto.valorDesconto) +
                      parseFloat(produto.valorAcrescimo)
                    ).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}`}
                  </span>
                </div>
              
             
                      
             )}


            </>
          );
        })}
        </div>
        )
      )}
    </div>
  );
};

export default TelaVendas;
