import React, { useState, useEffect, useCallback } from 'react';
import './FinalizarVenda.css';

const FinalizarVenda = ({
  consumidorIdentificado,
  setConsumidorIdentificado,
  closeModal,
  nomeCupom,
  documentoCupom,
  setNomeCupom,
  setDocumentoCupom,
  consumidor,
  setConsumidor,
  precoTotal,
  pagamentoConcluido,
  setPagamentoConcluido,
  pagamentos,
  setPagamentos,
  produtos,
  setProdutos,
  subTotal,
  CalcularTotais,
  acresDescFrete,
  setAcresDescFrete,
  totalDescontos,
  totalAcrescimos,
  acresDescFreteConcluido,
  setAcresDescFreteConcluido,
  setPagItem,
  pagItem,
}) => {
  const [isModalVisibleConsumidor, setIsModalVisibleConsumidor] = useState(false);
  const [isModalVisibleParametros, setIsModalVisibleParametros] = useState(false);
  const [isModalVisiblePagamento, setIsModalVisiblePagamento] = useState(false);
  const [isModalVisibleAcresDesc, setIsModalVisibleAcresDesc] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleModalIdentificarConsumidor = () => {
    setIsModalVisibleConsumidor(!isModalVisibleConsumidor);
  };

  const toggleModalParametros = () => {
    setIsModalVisibleParametros(!isModalVisibleParametros);
  };

  const toggleModalPagamento = () => {
    setIsModalVisiblePagamento(!isModalVisiblePagamento);
  };

  const toggleModalAcresDesc = () => {
    setIsModalVisibleAcresDesc(!isModalVisibleAcresDesc);
  };

  const emitirCupom = () => {
    console.log('Emitir Cupom acionado');
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      alert('Cupom emitido com sucesso!');
    }, 2000);
  };

  return (
    <div className="container">
      <header className="modal-header">
        <h1>Finalizar Venda</h1>
        <button onClick={closeModal} className="close-button">&times;</button>
      </header>
      <div className="container-buttons">
        <div className="row">
          <button
            className={`button ${consumidorIdentificado ? 'button-success' : 'button-danger'}`}
            onClick={toggleModalIdentificarConsumidor}
          >
            Identificar Consumidor
          </button>
        </div>
        <div className="row">
          <button className="button button-warning" onClick={toggleModalAcresDesc}>
            Acréscimos e Descontos
          </button>
        </div>
        <div className="row">
          <button className="button button-dark" onClick={toggleModalPagamento}>
            Pagamento
          </button>
        </div>
        <button className="button button-primary" onClick={toggleModalParametros}>
          Parâmetros
        </button>
        <button
          className={`button ${pagamentoConcluido && !loading ? 'button-success' : 'button-disabled'}`}
          onClick={emitirCupom}
          disabled={!pagamentoConcluido || loading}
        >
          {loading ? 'Processando...' : 'Emitir Cupom'}
        </button>
      </div>

      <div className="card">
        <p>Subtotal: {subTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
        <p>Desconto: {totalDescontos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
        <p>Acréscimos: {totalAcrescimos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
        <p>Frete: {acresDescFrete.frete.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
        <p>Total: {precoTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
      </div>
    </div>
  );
};

export default FinalizarVenda;